<template>
  <div class="h-screen bg-white-4">
      <div class="text-green-1 relative">
        <div class="flex flex-col">
            <Nav class="z-50" />
            <div class="app-banner-container xs:h-60 sm:h-80 md:h-96 lg:h-100 w-full space-top">
                <div class="overlay-color">
                <div class="max-w-screen-lg mx-auto px-4 h-full flex items-center">
                    <div class="flex-1" />
                        <div class="flex-1 text-center">
                            <div class="relative flex flex-col items-center my-4">
                                <h1 class="font-semibold text-lg sm:text-xl md:text-2xl lg:text-4xl z-10 text-white-1">
                                    #ConfidenceInAClick
                                </h1>
                                <div class="bg-pink-3 w-full mobileL:w-52 sm:w-56 md:w-64 lg:w-96 h-4 md:h-5 lg:h-7 top-3 md:top-4 lg:top-6 absolute rounded-sm" />
                            </div>
                            <p class="font-semibold text-xs sm:text-sm lg:text-lg text-white-1 lg:px-5 xl:px-0">
                                We're making beauty services accessible to you with just a few
                                taps. Download the Lay Bare app now
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full px-4 my-7">
                <div class="w-10/12 mx-auto">
                    <div class="flex justify-center gap-10 text-sm">
                        <router-link
                          to="/app/download"
                          class="border-brown-10 border-2 rounded-lg py-2 px-1 md:px-12 lg:text-lg 2xl:text-xl text-brown-10 tab-shadow" activeClass="bg-brown-1 font-semibold">
                          App Overview
                        </router-link>
                        <router-link
                          to="/app/referral"
                          class="border-brown-10 border-2 rounded-lg py-2 px-1 md:px-12 lg:text-lg 2xl:text-xl text-brown-10 tab-shadow" activeClass="bg-brown-1 font-semibold">
                          Referral Mechanics
                        </router-link>
                    </div>
                </div>
            </div>

            <router-view class="z-40" />

            <div class="w-full bg-gradient-to-r from-pink-9 to-pink-10" >
                <div class="md:max-w-screen-md xl:max-w-screen-lg mx-auto px-4">
                    <div class="flex flex-col lg:flex-row items-center">
                        <img class="w-10 lg:w-16 mt-5 lg:hidden" src="../../assets/images/download-page/heart.png" />
                        <div class="text-center lg:text-left text-white-1">
                            <img class="w-10 lg:w-16 mt-5 hidden lg:block" src="../../assets/images/download-page/heart.png" />
                            <p class="font-semibold my-2 lg:text-xl">
                            Here are just some of the cool things you can look forward to
                            when you download it, in the coming months, we'll be adding cool
                            features so stay tuned and watch for the update!
                            </p>
                            <div class="flex justify-center lg:justify-start lg:mb-6">
                            <a href="https://apps.apple.com/ph/app/lay-bare/id1492384941" target="_blank" @click="downloadAppBtnClicked('IOS')">
                                <img class="sm:w-32 mobileL:w-28" src="../../assets/images/home-page/header/app-store.png" alt="App store" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.lbo.mobile.laybare&hl=en&gl=US" target="_blank" @click="downloadAppBtnClicked('Android')">
                                <img class="sm:w-32 mobileL:w-28" src="../../assets/images/home-page/header/googleplay.png" alt="Play store" />
                            </a>
                            <a href="https://appgallery.huawei.com/#/app/C103688251" target="_blank"  @click="downloadAppBtnClicked('Huawei')">
                                <img class="sm:w-32 mobileL:w-28" src="../../assets/images/home-page/header/Huawei.png" alt="App gallery" />
                            </a>
                            </div>
                        </div>
                        <div class="img-container lg:self-end xl:pt-5">
                            <img class="sm:w-80 lg:w-160" src="../../assets/images/download-page/appqrcode.png" />
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer class="z-40" />
        </div>
      </div>
    </div>
</template>

<script>
import Nav from '../navigation/LaybareHeader.vue';
import Footer from '../footer/LaybareFooter.vue';
export default {
    components: {
      Nav,
      Footer
    },

    data() {
        return {
            isReferralTabActive: false
        }
    },

    watch: {
        '$route' : {
            immediate: true,
            handler: function(route){
                if (route.name === 'Referral') {
                    this.isReferralTabActive = true;
                    return
                }

                this.isReferralTabActive = false;
            }
        }
    },

    methods: {
        downloadAppBtnClicked(platform) {
            if (process.env.VUE_APP_NODE_ENV !== "production") return;

            this.$gtag.event('download_clicked', {
                event_category: 'App Download',
                event_label: platform,
                value: 1
            });
        }
    }
}
</script>

<style scoped>
.space-top {
    margin-top: 90px;
}

.app-banner-container {
  background: url('../../assets/images/download-page/download-app-banner.jpg')
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.tab-shadow {
    box-shadow: 0px 2px 3px 0px #00000040;
}

@media (max-width: 480px) {
    .space-top {
        margin-top: 60px;
    }
}

@media (min-width: 1280px) {
    .space-top {
        margin-top: 100px;
    }
}
  
@media (min-width: 1536px) {
    .space-top {
        margin-top: 128px;
    }
}
</style>